.message {
	animation: message--slide-up 200ms ease-out;
	margin-block: 25px;
	border: 1.5px solid currentColor;
	border-radius: 4px;
	padding: 15px;
	color: var(--color-theme-2);

	&.message--success { color: var(--color-signal-success); }
	&.message--warning { color: var(--color-signal-warning); }

	& > .text.text.text {
		color: inherit;
	}

}

.message__label {
	display: block;
	margin-bottom: 15px;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	font-size: 0.875em;
	font-weight: 600;
}

@keyframes message--slide-up {
	from {
		translate: 0 10px;
		opacity: 0;
	}
}
