.header {

	border-top: 20px solid var(--color-brand);
	background-color: var(--color-theme-8);

	@media (width < 720px) {
		border-top-width: 10px;
	}

}

.header__canvas {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	gap: 20px;
	margin-inline: auto;
	width: 650px;
	max-width: 100%;
	height: clamp(80px, calc(5rem + ((1vw - 6px) * 8.88888)), 160px);; /* 80px @ 600px ↔︎ 160px @ 1500px */
	padding: clamp(10px, calc(0.625rem + ((1vw - 6px) * 1.11111)), 20px) 20px; /* 10px @ 600px ↔︎ 20px @ 1500px */
}

.header__logo {
	width: auto;
	height: 100%;
	object-fit: contain;
}
