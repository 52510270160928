.heading {

	margin: 50px 0 15px;
	line-height: 1.25;
	font-weight: 500;
	color: var(--color-brand);

	&.heading--1 { font-size: 1.75em; }
	&.heading--2 { font-size: 1.5em; }

	&.heading--3 {
		text-transform: uppercase;
		letter-spacing: 0.025em;
		font-weight: normal;
		color: var(--color-theme-2);
	}

	&:has(+ .text) {
		margin-bottom: 10px;
	}

}
