.attendee-list {

	position: relative;
	min-height: 40px;

	/* Spinner (when empty): */
	&:is(:empty, :has(> template:only-child))::before {
		animation: hide 200ms step-end, spin 1s 200ms linear infinite;
		position: absolute;
		width: 40px;
		height: 40px;
		border: 3px solid var(--color-theme-5);
		border-right-color: transparent;
		border-radius: 50%;
		content: "";
	}

	& > li {

		position: relative;
		display: flex;
		align-items: baseline;
		gap: 15px;

		&:not(:only-child) {

			padding-bottom: 50px;

			&:not(:last-child) {
				margin-bottom: 30px;
			}

			&::after {
				position: absolute;
				inset: auto min(calc((100vw - 610px) / -2), -20px) 0;
				margin-inline: 20px;
				border-top: 3px dotted var(--color-theme-7);
				content: "";
			}

		}

		&:nth-last-child(n+2)::before,
		&:nth-last-child(n+2) ~ li::before {
			flex-shrink: 0;
			position: sticky;
			top: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: -45px;
			width: 30px;
			height: 50px;
			border-radius: 15px;
			background: var(--color-theme-8) svg-load("../icons/person.svg", fill=$color-brand) center 5px/20px no-repeat;
			padding-top: 20px;
			text-align: center;
			letter-spacing: 0.0625em;
			font-weight: 450;
			color: var(--color-theme-3);
			content: attr(data-count);
			user-select: none;
		}

		& > form {
			flex-grow: 1;
		}

		@media (width < 720px) {

			--offset: min(calc((100vw - 610px) / -2), -20px);
			gap: max(calc((var(--offset)* -1) - 30px), 15px);

			:root:root:root &::before {
				margin-left: var(--offset);
				border-radius: 0 10px 10px 0;
			}

		}

	}

}
