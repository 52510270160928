button,
.button {

	transition: box-shadow 200ms;
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	min-width: 40px;
	height: 40px;
	border-radius: 4px;
	background-color: var(--color-theme-8);
	padding-inline: 15px;
	text-align: center;
	text-transform: uppercase;
	line-height: 1.25;
	letter-spacing: 0.025em;
	font-size: 0.9375em;
	font-weight: 450;
	color: var(--color-signal-action);
	cursor: default;
	user-select: none;
	touch-action: manipulation;

	&.button--primary {

		min-width: 250px;
		height: 50px;
		background-color: var(--color-brand);
		padding-inline: 25px;
		font-size: 1.125em;
		letter-spacing: 0.05em;
		color: var(--color-default-background);

		&[data-pending]::after {
			background-color: rgb(255 255 255/90%);
		}

		&:focus-visible {
			border: 2px solid rgb(255 255 255/90%);
		}

		@media (width < 500px) {
			width: 100%;
		}

	}

	&:has(.button__icon--super) {
		padding-right: 40px;
	}

	&[data-pending]::after {
		animation: button--progress 1s ease-in-out infinite;
		position: absolute;
		inset: auto 0 5px 0;
		margin-inline: 5px;
		height: 2px;
		background-color: currentColor;
		content: "";
	}
	&:is([data-warning], [data-success]) > :not(svg) {
		color: transparent;
	}
	&[data-warning] { color: var(--color-signal-warning); }
	&[data-success] { color: var(--color-signal-success); }

	&:not(.button--primary):focus-visible {
		outline-offset: -2px;
		background-color: var(--color-default-background);
	}

	&:disabled {
		border: 2px solid var(--color-theme-8);
		background-color: var(--color-default-background);
		padding-inline: 13px;
		color: var(--color-theme-6);
		cursor: not-allowed;
	}

	@media (hover: hover) {
		&:not(.button--primary):not(:disabled):hover {
			transition-delay: 50ms;
			box-shadow: inset 0 -2px 0 var(--color-theme-7);
		}
	}

}

.button__icon {

	position: absolute;
	inset: 0;
	margin: auto;
	width: 25px;
	height: 25px;
	fill: currentColor;

	&.button__icon--super {
		opacity: 0.5;
		inset: 0 0 auto auto;
		margin: 5px;
		width: 20px;
		height: 20px;
	}

	:is(button, .button):not([data-pending]) > & > use[data-if="pending"],
	:is(button, .button):not([data-success]) > & > use[data-if="success"],
	:is(button, .button):not([data-warning]) > & > use[data-if="warning"] {
		display: none;
	}

}

@keyframes button--progress {
	0% {
		transform-origin: left center;
		scale: 0 100%;
	}
	50% {
		transform-origin: left center;
	}
	50.01% {
		transform-origin: right center;
		scale: 100%;
	}
	100% {
		transform-origin: right center;
		scale: 0 100%;
	}
}
