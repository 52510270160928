.link {

	border-radius: 0.0125em; /* For a small radius on the focus outline. */
	text-decoration: underline;
	text-decoration-style: dotted;
	text-decoration-thickness: from-font;
	text-underline-offset: 0.3125em;
	color: var(--color-signal-action);

	&:focus-visible {
		outline-width: 3px;
		outline-color: highlight;
		background-color: highlight;
		text-decoration: none;
	}

}
