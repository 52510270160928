.tag {

	display: inline-block;
	max-width: 100%;
	overflow: clip;
	border: 1.5px solid currentColor;
	border-radius: 4px;
	padding: 5px 5px calc(5px - var(--text-baseline-offset, 0px));
	text-overflow: ellipsis;
	text-transform: uppercase;
	text-wrap: nowrap;
	line-height: 1;
	letter-spacing: 0.025em;
	font-weight: 500;
	color: var(--color-theme-2);

	&.tag--subdued { color: var(--color-theme-6); }
	&.tag--success { color: var(--color-signal-success); }
	&.tag--warning { color: var(--color-signal-warning); }

}
