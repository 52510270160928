.footer {
	display: flex;
	justify-content: center;
	position: relative;
	background-color: var(--color-theme-0);
	padding-inline: 20px;
}

.footer__canvas {
	display: flex;
	align-items: center;
	gap: 25px;
	text-transform: uppercase;
	text-wrap: nowrap;
	letter-spacing: 0.025em;
	font-size: 0.8125em;
	color: var(--color-theme-6);

	a {

		padding-block: 10px;
		text-decoration-thickness: from-font;
		text-underline-offset: 0.25em;

		&:has(> svg) {
			display: inline-flex;
			align-items: center;
		}

		& > svg {
			margin-block: -5px;
			margin-right: 0.25em;
			width: 1.375lh;
			height: 1.375lh;
			fill: currentColor;
		}

		&:focus-visible {
			outline-style: none;
			text-decoration: underline;
		}

		@media (hover: hover) {
			&:hover {
				text-decoration: underline;
			}
		}

	}

}

/* Warning! 💀 Here be hard-coded colors, arrr! 💀 */
.footer__logo {

	transition: color 200ms;
	content-visibility: hidden;
	position: absolute;
	inset: auto 0 100% auto;
	display: flex;
	align-items: center;
	gap: 1ch;
	padding: 10px;
	text-align: right;
	line-height: 0.875;
	letter-spacing: -0.0125em;
	font-family: sans-serif;
	font-size: 13px;
	color: transparent;

	& > svg {
		display: block;
		width: 20px;
		height: 20px;
		border: 1.5px solid currentColor;
		border-radius: 50%;
		fill: currentColor;
		color: cornflowerblue;
	}

	@media (hover: hover) {

		/* Only display for UAs with hover (so probably no mobile): */
		content-visibility: visible;

		&:hover {
			transition-delay: 50ms;
			color: cornflowerblue;
		}

	}

}
