input,
select,
textarea {

	transition: border-color 200ms;
	max-width: 100%;
	accent-color: var(--color-signal-action);
	background-color: var(--color-default-background);
	border: 2px solid transparent;
	border-radius: 4px;
	background-color: var(--color-theme-8);

	&.input--token {
		text-transform: uppercase;
		font-family: monospace;
		font-size: 16px; /* PRevents zooming on iOS. */
	}

	&.input--code {
		font-family: monospace;
		font-size: 14px;
		white-space: pre;
	}

	&:disabled {
		opacity: 1; /* Chrome sets opacity to 0.7 for disabled inputs. */
		border-color: var(--color-theme-8);
		background-color: var(--color-default-background);
		color: var(--color-theme-6);
		cursor: not-allowed;
	}

	[data-invalid] &:invalid {
		border-color: currentColor;
		background-color: var(--color-default-background);
		color: var(--color-signal-warning);
	}

	&:focus-visible {
		outline-offset: -2px;
		background-color: var(--color-default-background);
	}

	@media (hover: hover) {
		&:enabled:hover {
			transition-delay: 50ms;
			border-color: var(--color-theme-7);
		}
	}

}

input:not(:is([type="checkbox"], [type="radio"])) {

	height: 40px;
	text-indent: 10px;

	&:not([size]) {
		width: 100%;
	}

	&.--align-center { text-indent: 0; }
	&.--align-right  { text-indent: 0; padding-right: 10px; }

}

input[type="checkbox"],
input[type="radio"] {

	appearance: none;
	width: 25px;
	height: 25px;

	&:checked {
		background-image: svg-load("../icons/check.svg");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat
	}

}

input[type="radio"] {
	border-radius: 50%;
}

textarea {

	min-height: calc(2lh + 16px);
	max-height: calc(10lh + 16px);
	padding: 6px 10px;
	line-height: 1.5;
	resize: vertical;

	&:not([cols]) {
		width: 100%;
	}

	&::-webkit-resizer {
		appearance: none;
		background: svg-load("../icons/drag-vertical.svg", fill=$color-theme-4) center bottom -2px/cover no-repeat;
	}

}

select {

	appearance: none;
	min-width: 150px;
	height: 40px;
	background: var(--color-theme-8) svg-load("../icons/chevron-down.svg", fill=$color-theme-4) center right 5px/25px no-repeat;
	padding-inline: 10px 30px;
	line-height: 1.5; /* Prevents type descenders from being cut off, weirdly. */
	text-overflow: ellipsis;

	&:focus-visible {
		background-image: svg-load("../icons/chevron-down.svg", fill=$color-signal-action);
	}

	&:disabled {
		background-image: svg-load("../icons/chevron-down.svg", fill=$color-theme-6);

	}

}

::placeholder {
	font-variation-settings: "slnt" -10;
	font-size: 0.9375em;
	font-weight: 350;
	color: var(--color-theme-5);
}
