details {

	margin: 25px 0;
	padding-left: 20px;

	& > summary {
		display: flex;
		align-items: center;
		gap: 5px;
		list-style: none;
		margin-left: -20px;
		width: fit-content;
		border-radius: 0.0125em; /* For a small radius on the focus outline. */
		padding-right: 2px; /* For a balanced outline when focused. */
		text-transform: uppercase;
		line-height: 1.5;
		letter-spacing: 0.025em;
		font-size: 0.875em;
		font-weight: 500;
		color: var(--color-theme-2);
		cursor: default;
		user-select: none;
		touch-action: manipulation;

		&::-webkit-details-marker {
			display: none;
		}

		&::before {
			transition: rotate 200ms;
			margin-block: -5px -3px; /* Aligns better with text */
			width: 15px;
			height: 25px;
			background: svg-load("../icons/chevron-right.svg", fill=$color-theme-3) center/cover no-repeat;
			content: "";
		}

		&:focus-visible {

			outline-width: 3px;
			outline-color: highlight;
			background-color: highlight;
			text-decoration: none;
			color: var(--color-signal-action);

			&::before {
				background-image: svg-load("../icons/chevron-right.svg", fill=$color-signal-action);
			}

		}

	}

	&::details-content {
		transition: height 200ms, content-visibility 200ms allow-discrete;
		height: 0;
		overflow: clip;
	}
	&[open] {

		& > summary::before {
			rotate: 0.25turn;
		}

		&::details-content {
			height: auto;
		}

	}

}

.details__canvas {

	padding-top: 10px;

	&:has(> .heading:first-child) {
		padding-top: 25px;
	}

}
