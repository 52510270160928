.progress {

	align-self: center;
	position: relative;
	display: flex;
	justify-content: space-between;
	gap: 40px;
	margin-bottom: 50px;
	cursor: default;

	& > li {

		transition: color 200ms;
		display: flex;
		align-items: center;
		gap: 10px;
		text-transform: uppercase;
		line-height: 1.25;
		letter-spacing: 0.05em;
		font-size: 0.875em;
		font-weight: 500;

		&::before {
			animation: spin 10s linear infinite;
			transition: border-color 200ms, background-position 200ms;
			width: 2lh;
			height: 2lh;
			border: 2px dashed var(--color-theme-6);
			border-radius: 50%;
			background: var(--color-default-background) svg-load("../icons/check.svg", fill=$color-signal-success) center bottom -2lh/90% no-repeat;
			content: "";
		}

		&[data-complete] {

			color: var(--color-theme-5);

			&::before {
				animation: none;
				border: 2px solid var(--color-signal-success);
				background-position: center;
			}

		}

		& > a:focus-visible {
			outline-width: 3px;
			outline-color: highlight;
			border-radius: 0.0125em;
			background-color: highlight;
			color: var(--color-signal-action);
		}

	}

	@media (width < 500px) {

		margin-bottom: 40px;

		& > li {
			flex-direction: column;
			align-items: flex-start;
		}

	}

}
