.text {

	margin-bottom: 25px;
	text-wrap: pretty;
	line-height: 1.625;

	&.text--compact {
		margin-bottom: 10px;
	}

	strong {
		font-weight: 600;
	}

	&.text--intro {
		font-size: 1.125em;
		line-height: 1.5;
		color: var(--color-theme-1);
	}

}
