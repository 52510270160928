.category-list > li {

	&[data-animate] {
		animation: hide 200ms step-end, category-list--slide-in 200ms 200ms ease-out;
	}

	&[data-highlight] {
		animation: category-list--shake 1s ease-in-out;
	}

}

@keyframes category-list--slide-in {
	from {
		translate: -40px 0;
		opacity: 0;
	}
}

@keyframes category-list--shake {
  10%, 90%      { translate: -2px 0; }
  20%, 80%      { translate:  4px 0; }
	30%, 50%, 70% { translate: -8px 0; }
  40%, 60%      { translate:  8px 0; }
}
