.checkbox-group {

	display: flex;
	align-items: flex-start;
	gap: 10px;
	width: fit-content;
	text-wrap: pretty;
	line-height: 1.5;

	& > input[type="checkbox"] {
		flex-shrink: 0;
	}

	& > :not(input[type="checkbox"]):not(input[type="radio"]):not(.checkbox-group__label) {
		margin-top: 1px;
		user-select: none;
		touch-action: manipulation;
	}

	.link {
		font-weight: normal;
	}

	& + & {
		margin-top: 5px;
	}

}

.checkbox-group__label {
	font-size: 1.125rem;
}