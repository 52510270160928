.button-group {

	display: flex;

	.form__label + & {
		margin-top: 5px;
	}

	& > :is(button, .button) {

		height: auto;
		min-height: 40px;
		border-radius: 0;
		padding-block: 8px;

		&:first-child { border-radius: 4px 0 0 4px; }
		&:last-child  { border-radius: 0 4px 4px 0; }

		&:not(:last-child):not(:disabled):not(:has(+ :disabled))::after {
			position: absolute;
			inset: 6px 0 6px auto;
			width: 2px;
			border-radius: 2px;
			background-color: var(--color-theme-7);
			content: "";
		}

		&:focus-visible::after,
		&:has(+ :focus-visible)::after {
			display: none;
		}

	}

}
