.order-card {

	transition: height 200ms ease-out;
	position: relative;
	display: flex;
	justify-content: center;
	overflow: clip;
	height: calc-size(max-content, size);
	border-radius: 6px;
	background-color: var(--color-theme-8);
	padding: 40px 20px;

	/* Spinner (when empty): */
	&:is(:empty, :has(> template:only-child)) {

		height: 200px;

		&::before {
			animation: hide 200ms step-end, spin 1s 200ms linear infinite;
			position: absolute;
			inset: 50%;
			margin: -20px;
			width: 40px;
			height: 40px;
			border: 3px solid var(--color-theme-5);
			border-right-color: transparent;
			border-radius: 50%;
			content: "";
		}

	}

	@media (width < 500px) {

		margin-inline: -20px;
		border-radius: 0;

		td {
			max-width: calc(100vw - 40px - 110px); /* Needed for text overflow. */
		}

	}

	@media (width >= 500px) {

		/* Dog ear gimmick: */
		clip-path: polygon(calc(100% - 40px) 0, 100% 40px, 100% 100%, 0 100%, 0 0);

		&::after {
			position: absolute;
			inset: 0 0 auto auto;
			width: 40px;
			height: 40px;
			border-bottom-left-radius: 6px;
			background-color: var(--color-theme-7);
			content: "";
		}

		td {
			max-width: calc(min(calc(100vw - 40px), 610px) - 40px - 110px); /* Needed for text overflow. */
		}

	}

}

.order-card__table {

	margin-block: -3px;
	width: 100%;
	border-collapse: collapse;
	line-height: 1.5;

	th,
	td {
		vertical-align: top;
	}

	th {

		width: 35%;
		padding-block: 5px;
		padding-right: 20px;
		text-align: right;
		text-transform: uppercase;
		letter-spacing: 0.025em;
		font-size: 0.875em;
		font-weight: 500;
		color: var(--color-theme-3);

		@media (width < 500px) {
			width: 110px;
		}

	}

	td {

		overflow: clip;
		padding-block: 3px;
		text-overflow: ellipsis;

		strong {
			font-weight: 600;
		}

	}

}

.order-card__lines {

	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-block: -2px 10px;
	padding-bottom: 15px;
	border-bottom: 1.5px solid var(--color-theme-7);
	font-size: 1.125em;
	font-weight: 450;
	font-variant-numeric: tabular-nums;

	& > li {
		padding-left: 3ch;
		text-indent: -3ch;
	}

}

.order-card__label {
	font-size: 0.75rem;
	color: var(--color-theme-4);
}
