.social-list {

	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 100%;

	& > label {

		position: relative;

		& > input {
			max-width: 500px;
			padding-left: 125px;
			text-indent: 0;
		}

	}

}

.social-list__label {

	display: flex;
	align-items: center;
	gap: 5px;
	position: absolute;
	inset: 0 auto 0 0;
	margin: 2px 0 2px 2px;
	padding-left: 5px;
	text-transform: uppercase;
	letter-spacing: 0.025em;
	font-size: 0.875em;
	font-weight: 500;
	color: var(--color-theme-3);

	& > :not(svg) {
		padding-top: 2px;
	}

	& > svg {
		flex-shrink: 0;
		width: 20px;
		height: 20px;
		fill: var(--color-theme-5);
	}

	label:focus-within > & {

		color: var(--color-signal-action);

		& > svg {
			fill: currentColor;
		}

	}

}
