.form__field {

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
	margin-bottom: 25px;

	&.form__field--inline {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		gap: 10px 15px;
	}

}

.form__label {

	position: relative;
	display: block;
	font-size: 1.0625em;
	font-weight: 500;

	/* Workaround for a Chrome bug that doesn't do flexbox gap for fieldsets: */
	&:is(legend) {
		margin-bottom: 5px;
	}

	.form__field:has(:required) &::after {
		transition: scale 100ms, color 100ms;
		scale: 0;
		position: relative;
		top: -0.375lh;
		display: inline-block;
		margin-left: 0.125em;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: var(--color-signal-warning);
		content: "";
	}
	.form__field:has(:required:invalid) &::after {
		scale: 100%;
	}

	& > del {
		text-decoration-thickness: from-font;
		color: var(--color-theme-2);
	}

	[data-invalid] .form__field:has(:invalid) & {
		color: var(--color-signal-warning);
	}

}

.form__hint {
	display: block;
	max-width: 60ch;
	text-wrap: pretty;
	line-height: 1.25;
	font-variation-settings: "slnt" -10;
	font-size: 0.9375em;
	font-weight: 350;
	color: var(--color-theme-2);
}

.form__message {
	display: block;
	text-wrap: balance;
	line-height: 1.25;
	font-variation-settings: "slnt" -10;
	font-size: 0.9375em;
	font-weight: 350;
	color: var(--color-theme-2);
}

.form__message--success { color: var(--color-signal-success); }
.form__message--failure { color: var(--color-signal-warning); }

.form__footer {
	margin-top: 40px;
}
