.payment-options-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	max-width: 500px; /* This prevents orphans, but is a bit arbitrary. */

	& > li {

		width: 64px;
		height: 48px;
		border-radius: 6px;
		background: var(--color-theme-8) url("/assets/images/payment-options.png") left center/cover no-repeat;
		background-origin: border-box;
		color: transparent;
		cursor: default;
		user-select: none;

		&:nth-child( 2) { background-position-x:  -64px; }
		&:nth-child( 3) { background-position-x: -128px; }
		&:nth-child( 4) { background-position-x: -192px; }
		&:nth-child( 5) { background-position-x: -256px; border: 2px solid black; } /* Apple Pay */
		&:nth-child( 6) { background-position-x: -320px; border: 2px solid #ccc; } /* Google Pay */
		&:nth-child( 7) { background-position-x: -384px; }
		&:nth-child( 8) { background-position-x: -448px; border: 2px solid #ccc; } /* Bancontact */
		&:nth-child( 9) { background-position-x: -512px; }
		&:nth-child(10) { background-position-x: -576px; }
		&:nth-child(11) { background-position-x: -640px; }
		&:nth-child(12) { background-position-x: -704px; }
		&:nth-child(13) { background-position-x: -768px; }
		&:nth-child(14) { background-position-x: -832px; }
		&:nth-child(15) { background-position-x: -896px; }

	}

}
